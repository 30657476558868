<template>
  <div class="register-view">
    <div class="tabs">
      <b-link class="options active"> 注册 </b-link>
    </div>
    <div class="form">
      <div class="current">
        <div class="input">
          <b-form-input
            type="text"
            placeholder="请输入手机号码"
            maxlength="11"
            v-model="phone"
          ></b-form-input>
        </div>
        <div class="input" style="display: flex">
          <b-form-input
            type="text"
            placeholder="请输入手机号码"
            maxlength="4"
            v-model="code"
            style="width: 60%"
          ></b-form-input>
          <b-button
            variant="warning"
            style="width: 35%; margin-left: 5%; background: #ff984e; color: #fff"
            @click="sendCode"
            :disabled="time > 0"
            >{{ time == 0 ? "获取验证码" : time + "s" }}</b-button
          >
        </div>
      </div>
      <div class="submit">
        <b-form-checkbox v-model="accept"
          ><span style="margin-left: 10px"
            >我已阅读并接受《用户协议》、《隐私政策》</span
          ></b-form-checkbox
        >
        <b-button
          variant="warning"
          style="background: #ff984e; color: #fff; width: 100%; margin: 20px 0"
          @click="submit"
          :disabled="loading"
          >立即注册</b-button
        >
        <div class="line">
          <b-icon icon="star-fill" style="color: #ff984e"></b-icon>
          未满十八岁用户登录需征得监护人同意
        </div>
        <div class="line">
          <b-icon icon="star-fill" variant="secondary"></b-icon>
          未注册过的手机号将自动创建为美蜂账号
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const testReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
export default {
  data() {
    return {
      phone: "",
      code: "",
      time: 0,
      accept: false,
      loading: false
    };
  },
  methods: {
    sendCode() {
      if (!testReg.test(this.phone))
        return this.$store.commit("showToast", "请输入正确的手机号码格式");
      let data = {
        mobile: this.phone,
        event: "mobilelogin",
      };
      this.$request.sendCode(data).then((res) => {
        let { code, msg } = res;
        if (code == 1) {
          this.$store.commit("showToast", "发送成功");
          this.time = 60;
          setInterval(() => {
            if (this.time > 0) return this.time--;
          }, 1000);
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    submit() {
      if (!this.accept) return this.$store.commit("showToast", "请同意用户协议及隐私政策");
      this.loading = true
      let data = {
        mobile: this.phone,
        captcha: this.code,
        code: "",
      };
      this.$request
        .mobileLogin(data)
        .then((res) => {
          this.loading = false;
          let { data, code, msg } = res;
          if (code == 1) {
            this.$cache.setCache("userInfo", JSON.stringify(data.userinfo));
            this.$store.commit("showToast", "登录成功");
            setTimeout(() => {
              this.$router.push({
                path: "/doctorInfo",
              });
            }, 2000);
          } else {
            this.$store.commit("showToast", msg);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../login/login.scss";
.register-view {
  margin-top: 62px;
}
</style>